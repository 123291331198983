import { UI_TYPES } from "./ui.types";

const INITIAL_STATE = { 
  showSearch: false,
};

export const uiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UI_TYPES.SHOW_SEARCH_TRUE:
      return {
        ...state,
        showSearch: action.show
      };
    default:
      return state;
  }
};