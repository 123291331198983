/* eslint-disable max-len */
export const ArrowRight = ({className}) => {
  return (
    <svg  className={`${className} inline-flex`} id="arrow-right" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path id="Vector" d="M0,11.88,4.89,6.99a1.489,1.489,0,0,0,0-2.1L0,0" transform="translate(6.683 3.06)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      <path id="Vector-2" data-name="Vector" d="M0,0H18V18H0Z" transform="translate(18 18) rotate(180)" fill="none" opacity="0"/>
    </svg>
  );
};

