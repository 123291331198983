/* eslint-disable max-len */
import { Link } from "react-router-dom";
import { ArrowUp } from "../../assets/images/icons/ListingsIcons";

export default function Multiforms({ children }) {
  return (
    <div className="bg-[#f7f7f7]" >
      <div aria-label="menu nav" className="bg-transparent bg-[#f7f7f7] border-b border-[#E4E4E4]  mt-0   w-full ">

        <div className="flex  w-full lg:flex-wrap items-center h-full">
          <div className="flex flex-shrink md:w-[100px]  justify-center md:justify-start md:border-r py-6 px-3  border-[#E4E4E4] ">
            <a href="/" aria-label="Home" className="flex justify-center w-full">
              <img src="/account/images/cn-icon.png" alt="logo" width={42} />
            </a>
          </div>
          <div className="flex justify-between  items-center lg:mx-auto overflow-y-auto w-full  md:w-5/6 px-6">
            <div className="flex  justify-center py-6 md:justify-start ">
              <Link to={"/account/start"} className="text-base ">
                Go to my dashboard
              </Link>
            </div>
            <div className="  flex lg:hidden">
              <Link>Back <ArrowUp className={"inline-flex text-mainDarkGreen-100"} /></Link>

            </div>
          </div>


        </div>

      </div>
      <div className="flex flex-no-wrap min-h-screen ">

        <div className="md:w-[100px] absolute sm:relative md:border-r border-[#E4E4E4]  md:block bg-gradient-to-b  from-[#93D50A] to-[#D4EE9D] hidden sm:flex">
          <div>

          </div>

        </div>


        <div className="container mx-auto    md:w-5/6 px-6">
          {children}

        </div>
      </div>



     
    </div>
  );
}
