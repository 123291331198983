// import {useState} from "react";
// import FormMessage from "../FormMessage/FormMessage";

export default function Input({value,
  type, 
  heightClass = "h-12",
  name, 
  id, 
  onInputChange,
  className,
  placeholder, 
  autocomplete,
  isDiabled=false
}) {
  // const [inputState, setInputState] = useState({focus: false, visible: !!value, type, errorMessage: ''});
  id = id || name;
  
  return (
    <input
      id={id}
      name={name}
      value={value}
      autoComplete={autocomplete}
      type={type || 'text'}
      className={`${className} w-full outline-none bg-white   placeholder:text-sm
      placeholder:text-mainBlack-60 
      sm:text-sm ${isDiabled ? 'text-gray-500' : ''}
      focus:ring-0 
      focus:border-[#E3E3E4]
      appearance-none block border border-[#E3E3E4] rounded py-3 px-4 mb-3 leading-tight ${heightClass}`}
      placeholder={placeholder}
      onChange={onInputChange}
      disabled={isDiabled}
      // onFocus={() => setInputState((iState) => ({...iState, focus: true}))}
      // onBlur={() => {
      //   setInputState((iState) => ({...iState, focus: false, visible: !!value, type}));
      // }}
    />
  );
}
