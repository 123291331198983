import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'antd/dist/antd.css';
import './assets/Styles/tailwind.css';
import './assets/Styles/main.css';
// import './assets/Styles/index.css';

import App from './App';
import { store } from './Store/store';
import { createBrowserHistory } from 'history';

//i
const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);
