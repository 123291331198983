import { Link } from 'react-router-dom';
import { useState } from 'react'; // Import useState hook

export default function BizmixTopNavigation() {
  const links = [
    {
      title: 'Home',
      slug: '/bizmix#hero'
    },
    {
      title: 'About',
      slug: '/bizmix#about'
    },
    {
      title: 'Gallery',
      slug: '/bizmix/gallery'
    },
    {
      title: 'Contact',
      slug: '/bizmix#contact'
    }
  ];

  // Step 1: Add state to manage mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Step 2: Toggle the menu visibility when the button is clicked
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  return (
    <nav
      className="bg-white border-y-8 border-y-[#92D418] mx-auto w-full top-0 z-30 fixed"
      id="nav-menu"
    >
      <style>
        {`
          @media only screen and (max-width: 767px) {
            #nav-menu {
              background: green !important;
            }
          }
        `}
      </style>
      <div className="flex mx-auto  w-full py-2 px-8 items-center justify-between">
        <Link to="/" className="inline-block">
          <img src="/images/logo.svg" className="h-10 w-auto hidden md:inline-block" />
          <img
            src="/images/logo-white.svg"
            className="h-10 w-auto inline-block md:hidden"
          />
        </Link>

        {/* Step 3: Render mobile menu based on isMobileMenuOpen state */}
        {isMobileMenuOpen && (
          <div
            className="flex flex-col gap-1 md:hidden bg-[#92D418]"
            style={{
              position: 'absolute',
              background: '#92D418',
              width: '100%',
              top: '55px',
              left: '0',
              textAlign: 'center',
              padding: '20px'
            }}
          >
            {links.map(link => (
              <Link
                to={link.slug}
                key={link.title}
                className="font-bold text-black px-2 inline-block   hover:underline"
              >
                {link.title}
              </Link>
            ))}
          </div>
        )}

        <button className="md:hidden" onClick={toggleMobileMenu}>
          <MenuButton />
        </button>

        <div className="gap-1 hidden items-center md:inline-block">
          {links.map(link => (
            <Link
              to={link.slug}
              key={link.title}
              className="font-bold text-black px-2 inline-block hover:underline"
            >
              {link.title}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}

function MenuButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 text-mainOrange-100 w-8"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
    </svg>
  );
}
