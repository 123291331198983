import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { routeNames } from '../../../Constants/AccountConstants';
import { getUserState } from '../../../utils/utils';

function DropNav({ navs }) {
  return navs.map((nav, index) => (
    <Link to={nav.link} key={index} className={`__item ${nav.isActive ? 'active' : ''}`}>
      <div className="__icon">
        {nav.isActive && <img src="/account/icons/nav/dot-green.svg" alt={nav.name} />}
      </div>
      {nav.name}
    </Link>
  ));
}

export default function SideNav() {
  const selector = useSelector(state => state.authReducer);
  const userState = getUserState(selector).user.data.user;

  const location = useLocation();
  const [activeLink, setActiveLink] = React.useState(null);
  const path = window.location.pathname;

  React.useEffect(() => {
    const route = path.split('/')[2];
    setActiveLink(route);
  }, [path]);

  // Add drop down navs here
  const [openDrops, setOpenDrops] = React.useState({
    adverts: window.location.pathname.includes(routeNames.ADVERTS)
  });

  const navLinks = [
    {
      isActive: activeLink === routeNames.GET_STARTED || !activeLink,
      name: 'Get Started',
      link: '/account/' + routeNames.GET_STARTED,
      icon:
        activeLink === routeNames.GET_STARTED || !activeLink
          ? '/account/icons/nav/start-active.svg'
          : '/account/icons/nav/start.svg'
    },
    {
      isActive: activeLink === routeNames.DASHBOARD,
      name: 'Dashboard',
      link: '/account/' + routeNames.DASHBOARD,
      icon:
        activeLink === routeNames.DASHBOARD
          ? '/account/icons/nav/dashboard-active.svg'
          : '/account/icons/nav/dashboard.svg'
    },
    {
      isActive: activeLink === routeNames.BUSINESSES,
      name: 'Businesses',
      link: '/account/' + routeNames.BUSINESSES,
      icon:
        activeLink === routeNames.BUSINESSES
          ? '/account/icons/nav/businesses-active.svg'
          : '/account/icons/nav/businesses.svg'
    },
    {
      isActive: activeLink === routeNames.LISTINGS,
      name: 'Listings',
      link: '/account/' + routeNames.LISTINGS,
      icon:
        activeLink === routeNames.LISTINGS
          ? '/account/icons/nav/listings-active.svg'
          : '/account/icons/nav/listings.svg'
    },
    {
      isActive: activeLink === routeNames.ADVERTS,
      name: 'Adverts',
      link: '/account/' + routeNames.ADVERTS,
      icon:
        activeLink === routeNames.ADVERTS
          ? '/account/icons/nav/adverts-active.svg'
          : '/account/icons/nav/adverts.svg',
      isDropdown: true,
      dropComponent: (
        <DropNav
          navs={[
            {
              name: 'Create Ads',
              link: '/account/' + routeNames.ADVERTS,
              isActive: window.location.pathname === '/account/' + routeNames.ADVERTS
            },
            {
              name: 'Running Ads',
              link: '/account/' + routeNames.ADVERTS + '/list',
              isActive:
                window.location.pathname === '/account/' + routeNames.ADVERTS + '/list'
            }
          ]}
        />
      )
    },
    {
      isActive: activeLink === routeNames.INVOICES,
      name: 'Invoices',
      link: '/account/' + routeNames.INVOICES,
      icon:
        activeLink === routeNames.INVOICES
          ? '/account/icons/nav/invoice-active.svg'
          : '/account/icons/nav/invoice.svg'
    },
    {
      isActive: activeLink === routeNames.CONNECTIONS,
      name: 'Connections',
      link: '/account/' + routeNames.CONNECTIONS,
      icon:
        activeLink === routeNames.CONNECTIONS
          ? '/account/icons/nav/others-active.svg'
          : '/account/icons/nav/others.svg'
    }
  ];

  const moreLinks = [
    {
      isActive: activeLink === routeNames.CLUBCONNECT,
      name: 'Club Connect',
      link: '/account/' + routeNames.CLUBCONNECT,
      icon:
        activeLink === routeNames.CLUBCONNECT
          ? '/account/icons/nav/others-active.svg'
          : '/account/icons/nav/others.svg'
    },
    {
      isActive: activeLink === routeNames.QUOTE_REQUEST,
      name: 'Quote Request',
      link: '/account/' + routeNames.QUOTE_REQUEST,
      icon:
        activeLink === routeNames.QUOTE_REQUEST
          ? '/account/icons/nav/others-active.svg'
          : '/account/icons/nav/others.svg'
    }
  ];

  const [profileLinkIsOpen, setProfileLinkIsOpen] = React.useState(false);
  React.useEffect(() => {
    setProfileLinkIsOpen(false);
  }, [location]);

  const handleClickOutside = e => {
    if (!e.target.className.includes('profile-toggle')) {
      setProfileLinkIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (profileLinkIsOpen) document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [profileLinkIsOpen]);

  return (
    <div className="h-screen w-full p-5 sidebar account-sidenav">
      <div className={`profile-toggle transition ${profileLinkIsOpen ? 'open' : ''}`}>
        <div className="_top" onClick={() => setProfileLinkIsOpen(!profileLinkIsOpen)}>
          <div
            style={{
              width: '50px',
              marginRight: '10px',
              display: 'flex',
              justifyContent: 'center',
              // alignSelf: 'stretch'
              alignItems: 'center'
            }}
          >
            <div
              style={{
                height: '40px',
                width: '40px',
                borderRadius: '50%',
                overflow: 'hidden'
              }}
            >
              <img
                src={userState.profile_picture}
                alt="profile"
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center center'
                }}
              />
            </div>
          </div>
          <div style={{ width: 'calc(100% - 100px)' }}>
            <h3 className="font-bold text-17px" style={{ lineHeight: '15px !important' }}>
              Welcome!
            </h3>
            <div className="text-17 capitalize" style={{ lineHeight: '16px' }}>
              {userState.first_name} {userState.last_name}
            </div>
          </div>
          <div
            style={{
              width: '40px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img
              src="/account/icons/arrow-down.svg"
              className={`transition ${profileLinkIsOpen ? 'rotate-180' : ''}`}
              alt="more"
            />
          </div>
        </div>
        <div className="_bottom">
          <hr className="my-2" />
          <Link to={`/create-business`} className="flex justify-between">
            <span className="_text_green">New Business</span>
            <img src="/account/icons/add-green.svg" alt="add" />
          </Link>
          <Link to={`/create-listing`} className="flex justify-between">
            <span className="_text_green">New Listing</span>
            <img src="/account/icons/add-green.svg" alt="add" />
          </Link>
          <hr className="my-2" />
          <Link to="/" className="flex">
            <img src="/account/icons/home.svg" className="mr-2" width={25} />
            Home
          </Link>
          <Link to="/account/profile" className="flex">
            <img src="/account/icons/settings.svg" className="mr-2" width={25} />
            Settings
          </Link>
          <Link to="/logout" className="flex">
            <img src="/account/icons/logout.svg" className="mr-2" width={25} />
            Logout
          </Link>
          <Link to="/logout-all-devices" className="flex">
            <img src="/account/icons/logout.svg" className="mr-2" width={25} />
            Logout of all devices
          </Link>
        </div>
      </div>
      <br />
      <div className="sidenav-links">
        <div className='__main'>
          {navLinks.map((link, index) => (
            <div key={index}>
              {link.isDropdown ? (
                <>
                  <Link
                    to={link.link}
                    key={index}
                    className={`rounded-[10px] sidenav-link ${
                      link.isActive ? 'active' : ''
                    } ${openDrops.adverts ? '_drop' : ''}`}
                    onClick={() => {
                      setOpenDrops({ ...openDrops, adverts: !openDrops.adverts });
                    }}
                  >
                    <img className="icon" src={link.icon} alt={link.name} />
                    <span className="text-17">{link.name}</span>
                  </Link>
                  <div className={`_dropdown_content ${openDrops.adverts ? '_open' : ''}`}>
                    {link.dropComponent}
                  </div>
                </>
              ) : (
                <Link
                  to={link.link}
                  key={index}
                  className={`rounded-[10px] sidenav-link ${link.isActive ? 'active' : ''}`}
                  onClick={() => {
                    setOpenDrops({
                      adverts: false
                    });
                  }}
                >
                  <img className="icon" src={link.icon} alt={link.name} />
                  <span className="text-17">{link.name}</span>
                </Link>
              )}
            </div>
          ))}

          <h4 className="font-bold" style={{ padding: '20px 20px 5px 20px' }}>
            MORE ON CN
          </h4>
          {moreLinks.map((link, index) => (
            <Link
              to={link.link}
              key={index}
              className={`rounded-[10px] sidenav-link ${link.isActive ? 'active' : ''}`}
            >
              <img className="icon" src={link.icon} alt={link.name} />
              <span className="text-17">{link.name}</span>
            </Link>
          ))}
        </div>

        <div className='__footer'>
          <Link
            to={"/account/"+routeNames.SAVED}
            className={`rounded-[10px] sidenav-link ${activeLink === routeNames.SAVED ? 'active' : ''}`}
            onClick={() => {
              setOpenDrops({
                adverts: false
              });
            }}
          >
            <img className="icon" src={activeLink === routeNames.SAVED ? 
              "/account/icons/nav/saved-active.svg" :
              "/account/icons/nav/saved.svg"
            }alt="My Saved" />
            <span className="text-17">My Saved</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
