export const BreifCase = ({className}  ) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="vuesax_bulk_briefcase" data-name="vuesax/bulk/briefcase" transform="translate(-108 -188)">
        <g id="briefcase" transform="translate(108 188)">
          <path id="Vector" d="M38.174,10.42c-1.7-1.88-4.54-2.82-8.66-2.82h-.48V7.52c0-3.36,0-7.52-7.52-7.52h-3.04c-7.52,0-7.52,4.18-7.52,7.52v.1h-.48c-4.14,
          0-6.96.94-8.66,2.82-1.98,2.2-1.92,5.16-1.72,7.18l.02.14.2,2.1a.436.436,0,0,0,.1.08c.66.44,1.34.88,2.06,1.28.28.18.58.34.88.5a34.331,34.331,0,0,0,11,
          3.76c.18,1.88,1,4.08,5.38,4.08s5.24-2.18,5.38-4.12a33.657,33.657,0,0,0,11.58-4.16c.12-.06.2-.12.3-.18a25.539,25.539,0,0,0,2.62-1.7.241.241,0,0,0,
          .1-.1l.08-.72.1-.94c.02-.12.02-.22.04-.36C40.094,15.26,40.054,12.5,38.174,10.42Zm-16,13.7c0,2.12,0,
          2.44-2.46,2.44s-2.46-.38-2.46-2.42V21.62h4.92ZM13.814,7.6V7.52c0-3.4,0-4.66,4.66-4.66h3.04c4.66,0,4.66,1.28,4.66,4.66v.1H13.814Z"
          transform="translate(4.006 3.54)" fill="rgba(6,21,43,0.8)" />
          <path id="Vector-2" data-name="Vector" d="M36.68,1.8l-.3.18A34.074,34.074,0,0,1,24.8,6.14c-.16,1.92-1,4.12-5.38,4.12S14.2,8.08,14.04,6.18a33.389,
          33.389,0,0,1-11-3.76c-.3-.16-.6-.32-.88-.5C1.44,1.52.76,1.08.1.64A.436.436,0,0,1,0,.56L1.22,13.58c.42,3.98,2.06,8.08,10.86,8.08H27.32c8.8,0,
          10.44-4.1,10.86-8.1L39.44,0a.241.241,0,0,1-.1.1A24.922,24.922,0,0,1,36.68,1.8Z" transform="translate(4.32 22.8)" fill="rgba(6,21,43,0.8)" opacity="0.4" />
          <path id="Vector-3" data-name="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};




export const Calendar = ({className}  ) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="vuesax_bulk_calendar" data-name="vuesax/bulk/calendar" transform="translate(-492 -188)">
        <g id="calendar" transform="translate(492 188)">
          <path id="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0" />
          <path id="Vector-2" data-name="Vector" d="M27.422,4.62V1.5a1.5,1.5,0,0,0-3,0v3h-13v-3a1.5,1.5,0,1,0-3,0V4.62C3.022,5.12.4,8.34,0,13.12a1,
          1,0,0,0,1,1.06h33.84a.992.992,0,0,0,1-1.06C35.442,8.34,32.822,5.12,27.422,4.62Z" transform="translate(6.078 2.5)" fill="rgba(6,21,43,0.8)" />
          <path id="Vector-3" data-name="Vector" d="M34,0a2.006,2.006,0,0,1,2,2V14.32c0,6-3,10-10,10H10c-7,0-10-4-10-10V2A2.006,2.006,0,0,1,2,0Z"
            transform="translate(6 19.68)" fill="rgba(6,21,43,0.8)" opacity="0.4" />
          <g id="Group" transform="translate(15 26.002)">
            <path id="Vector-4" data-name="Vector" d="M2,4a2.1,2.1,0,0,1-1.42-.58A2.1,2.1,0,0,1,0,2,2.1,2.1,0,0,1,.58.578,2,2,0,0,1,2.76.158a1.867,
            1.867,0,0,1,.66.42A2.1,2.1,0,0,1,4,2a2.1,2.1,0,0,1-.58,1.42A2.1,2.1,0,0,1,2,4Z" fill="rgba(6,21,43,0.8)" />
          </g>
          <g id="Group-2" data-name="Group" transform="translate(22 26.002)">
            <path id="Vector-5" data-name="Vector" d="M2,4a2.1,2.1,0,0,1-1.42-.58A2.1,2.1,0,0,1,0,2,2.1,2.1,0,0,1,.58.578a1.867,1.867,0,0,1,.66-.42,
            2,2,0,0,1,2.18.42A2.1,2.1,0,0,1,4,2a2.1,2.1,0,0,1-.58,1.42l-.3.24a1.515,1.515,0,0,1-.36.18,1.271,1.271,0,0,1-.36.12A2.71,2.71,0,0,1,2,4Z"
            transform="translate(0 0)" fill="rgba(6,21,43,0.8)" />
          </g>
          <g id="Group-3" data-name="Group" transform="translate(29 26)">
            <path id="Vector-6" data-name="Vector" d="M2,4A2.1,2.1,0,0,1,.58,3.42,2.1,2.1,0,0,1,0,2,2.1,2.1,0,0,1,.58.58,2.065,2.065,0,0,1,1.24.16,1.912,
            1.912,0,0,1,2.4.04a1.271,1.271,0,0,1,.36.12,1.515,1.515,0,0,1,.36.18l.3.24A2.1,2.1,0,0,1,4,2a2.1,2.1,0,0,1-.58,1.42l-.3.24a1.515,1.515,0,0,1-.36.18,
            1.271,1.271,0,0,1-.36.12A3.006,3.006,0,0,1,2,4Z" transform="translate(0 0)" fill="rgba(6,21,43,0.8)" />
          </g>
          <g id="Group-4" data-name="Group" transform="translate(15 33)">
            <path id="Vector-7" data-name="Vector" d="M2,4a1.991,1.991,0,0,1-.76-.16,2.065,2.065,0,0,1-.66-.42A2.1,2.1,0,0,1,0,2,2.1,2.1,0,0,1,.58.58,2.065,
            2.065,0,0,1,1.24.16,1.912,1.912,0,0,1,2.4.04a1.271,1.271,0,0,1,.36.12,1.515,1.515,0,0,1,.36.18l.3.24A2.1,2.1,0,0,1,4,2a2.1,2.1,0,0,1-.58,1.42,3.151,
            3.151,0,0,1-.3.24,1.515,1.515,0,0,1-.36.18,1.271,1.271,0,0,1-.36.12A2.71,2.71,0,0,1,2,4Z" transform="translate(0 0)" fill="rgba(6,21,43,0.8)" />
          </g>
          <g id="Group-5" data-name="Group" transform="translate(22 33.025)">
            <path id="Vector-8" data-name="Vector" d="M2,3.975a2.1,2.1,0,0,1-1.42-.58A2.1,2.1,0,0,1,0,1.975,2.1,2.1,0,0,1,.58.555a2.094,2.094,0,0,1,2.84,0A2.1,
            2.1,0,0,1,4,1.975a2.1,2.1,0,0,1-.58,1.42A2.1,2.1,0,0,1,2,3.975Z" fill="rgba(6,21,43,0.8)" />
          </g>
          <g id="Group-6" data-name="Group" transform="translate(29 33.025)">
            <path id="Vector-9" data-name="Vector" d="M2,3.975a2.1,2.1,0,0,1-1.42-.58A2.1,2.1,0,0,1,0,1.975,2.1,2.1,0,0,1,.58.555a2.094,2.094,0,0,1,2.84,
            0A2.1,2.1,0,0,1,4,1.975a2.1,2.1,0,0,1-.58,1.42A2.1,2.1,0,0,1,2,3.975Z" fill="rgba(6,21,43,0.8)" />
          </g>
        </g>
      </g>
    </svg>

  );
};

export const Car = ({className}  ) =>{
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="vuesax_bulk_car" data-name="vuesax/bulk/car" transform="translate(-172 -188)">
        <g id="car" transform="translate(172 188)">
          <path id="Vector" d="M41.863,6.82c-.3-3.3-1.18-6.82-7.6-6.82H8.743c-6.42,0-7.28,3.52-7.6,6.82L.023,19a5.458,5.458,0,0,0,1.4,4.18A5.612,
          5.612,0,0,0,5.583,25h3.76c3.24,0,3.86-1.86,4.26-3.08l.4-1.2c.46-1.38.58-1.72,2.38-1.72h10.24c1.8,0,1.86.2,2.38,1.72l.4,1.2c.4,1.22,1.02,3.08,
          4.26,3.08h3.76a5.645,5.645,0,0,0,4.16-1.82,5.458,5.458,0,0,0,1.4-4.18Z" transform="translate(2.497 20.5)" fill="rgba(6,21,43,0.8)" opacity="0.4"/>
          <path id="Vector-2" data-name="Vector" d="M37.5,10.34H35.46L34.7,6.72C33.98,3.22,32.48,0,26.52,0H12.48C6.52,0,5.02,3.22,4.3,6.72l-.76,3.62H1.5a1.5,
          1.5,0,0,0,0,3H2.92l-.84,4a9.272,9.272,0,0,1,4.66-1H32.26a9.272,9.272,0,0,1,4.66,1l-.84-4H37.5a1.5,1.5,0,0,0,0-3Z" transform="translate(4.5 4.16)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-3" data-name="Vector" d="M7.5,3h-6A1.511,1.511,0,0,1,0,1.5,1.511,1.511,0,0,1,1.5,0h6A1.511,1.511,0,0,1,9,1.5,1.511,1.511,0,0,1,7.5,
          3Z" transform="translate(10.5 28.5)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-4" data-name="Vector" d="M7.5,3h-6A1.511,1.511,0,0,1,0,1.5,1.511,1.511,0,0,1,1.5,0h6A1.511,1.511,0,0,1,9,1.5,1.511,1.511,0,0,1,7.5,
          3Z" transform="translate(28.5 28.5)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-5" data-name="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>

  );
};


export const Mobile = ({className}  ) =>{
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="vuesax_bulk_mobile" data-name="vuesax/bulk/mobile" transform="translate(-108 -380)">
        <g id="mobile" transform="translate(108 380)">
          <path id="Vector" d="M24.48,0H7.52C2,0,0,2,0,7.62V32.38C0,38,2,40,7.52,40H24.46C30,40,32,38,32,32.38V7.62C32,2,30,0,24.48,0Z" 
            transform="translate(8 4)" fill="rgba(6,21,43,0.8)" opacity="0.4"/>
          <path id="Vector-2" data-name="Vector" d="M9.5,3h-8A1.511,1.511,0,0,1,0,1.5,1.511,1.511,0,0,1,1.5,0h8A1.511,1.511,0,0,1,11,1.5,1.511,1.511,0,0,1,9.5,
          3Z" transform="translate(18.5 9.5)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-3" data-name="Vector" d="M7,3.5A3.5,3.5,0,1,1,3.5,0,3.5,3.5,0,0,1,7,3.5Z" transform="translate(20.5 31.6)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-4" data-name="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>

  );
};

export const Tag = ({className}  ) => {
  return  (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="vuesax_bulk_tag-2" data-name="vuesax/bulk/tag-2" transform="translate(-620 -444)">
        <g id="tag-2" transform="translate(620 444)">
          <path id="Vector" d="M2.81,25.053l9.06,9.06a9.56,9.56,0,0,0,13.5,0l8.78-8.78a9.56,9.56,0,0,0,0-13.5l-9.08-9.04a9.5,9.5,0,0,0-7.2-2.78l-10,.48A7.728,
          7.728,0,0,0,.49,7.833l-.48,10A9.608,9.608,0,0,0,2.81,25.053Z" transform="translate(5.531 5.547)" fill="rgba(6,21,43,0.8)" opacity="0.4"/>
          <path id="Vector-2" data-name="Vector" d="M11.52,5.76A5.76,5.76,0,1,1,5.76,0,5.76,5.76,0,0,1,11.52,5.76Z" transform="translate(13.24 13.24)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-3" data-name="Vector" d="M1.495,10.995a1.483,1.483,0,0,1-1.06-.44,1.509,1.509,0,0,1,0-2.12l8-8a1.5,1.5,0,0,1,2.12,2.12l-8,8A1.483,
          1.483,0,0,1,1.495,10.995Z" transform="translate(24.505 24.505)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-4" data-name="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>

  );
};

export const Devices = ({className}  ) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="vuesax_bulk_devices" data-name="vuesax/bulk/devices" transform="translate(-620 -252)">
        <g id="devices" transform="translate(620 252)">
          <path id="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0"/>
          <path id="Vector-2" data-name="Vector" d="M28,0H8C1.6,0,0,1.6,0,8V19.8c.06,6.06,1.74,7.6,8,7.6h4.5v7.1H5.9a1.5,1.5,0,0,0,0,3H20.5a8.836,8.836,0,0,
          1-.46-3H15.5V27.4h4.54V17.18c0-4.28,1.42-5.7,5.7-5.7H34.3a14.415,14.415,0,0,1,1.7.1V8C36,1.6,34.4,0,28,0Z" transform="translate(4 4)" fill="rgba(6,21,43,0.8)" opacity="0.4"/>
          <path id="Vector-3" data-name="Vector" d="M15.96.1a14.415,14.415,0,0,0-1.7-.1H5.7C1.42,0,0,1.42,0,5.7V23.02a8.836,8.836,0,0,0,.46,3c.7,1.82,2.28,
          2.5,5.24,2.5h8.56c4.28,0,5.7-1.42,5.7-5.7V5.7C19.96,2.04,18.92.48,15.96.1ZM9.98,4.7A3.14,3.14,0,1,1,6.84,7.84,3.132,3.132,0,0,1,9.98,4.7Zm0,18.12a4.28,
          4.28,0,1,1,2.8-7.52,4.344,4.344,0,0,1,1.48,3.24A4.285,4.285,0,0,1,9.98,22.82Z" transform="translate(24.04 15.48)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-4" data-name="Vector" d="M8.56,4.28A4.275,4.275,0,1,1,7.08,1.04,4.344,4.344,0,0,1,8.56,4.28Z" transform="translate(29.74 29.74)" fill="rgba(6,21,43,0.8)" opacity="0.4"/>
          <path id="Vector-5" data-name="Vector" d="M6.28,3.14A3.14,3.14,0,1,1,3.14,0,3.14,3.14,0,0,1,6.28,3.14Z" transform="translate(30.88 20.18)" fill="rgba(6,21,43,0.8)" opacity="0.4"/>
        </g>
      </g>
    </svg>

  );
};

export const Buildings = ({className}  ) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g id="vuesax_bulk_buildings" data-name="vuesax/bulk/buildings" transform="translate(-364 -188)">
        <g id="buildings" transform="translate(364 188)">
          <path id="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0"/>
          <path id="Vector-2" data-name="Vector" d="M10.22,0A7.757,7.757,0,0,0,10,2V8H0V4A4.012,4.012,0,0,1,4,0Z" transform="translate(10 8)" fill="rgba(6,21,43,0.8)" opacity="0.6"/>
          <path id="Vector-3" data-name="Vector" d="M16,22V0H6Q0,0,0,6V22q0,6,6,6H22Q16,28,16,22ZM9.5,18a1.5,1.5,0,0,1-3,0V10a1.5,1.5,0,0,1,3,0Z" transform="translate(4 16)" fill="rgba(6,21,43,0.8)"/>
          <path id="Vector-4" data-name="Vector" d="M8,40H6q-6,0-6-6V6Q0,0,6,0H18q6,0,6,6V34q0,6-6,6H16" transform="translate(20 4)" fill="rgba(6,21,43,0.8)" opacity="0.4"/>
          <path id="Vector-5" data-name="Vector" d="M8,2v8H0V2A2.006,2.006,0,0,1,2,0H6A2.006,2.006,0,0,1,8,2Z" transform="translate(28 34)" fill="rgba(6,21,43,0.8)"/>
          <g id="Group" transform="translate(26.5 14.5)">
            <path id="Vector-6" data-name="Vector" d="M1.5,13A1.511,1.511,0,0,1,0,11.5V1.5A1.511,1.511,0,0,1,1.5,0,1.511,1.511,0,0,1,3,1.5v10A1.511,1.511,0,0,1,1.5,13Z" fill="rgba(6,21,43,0.8)"/>
          </g>
          <g id="Group-2" data-name="Group" transform="translate(34.5 14.5)">
            <path id="Vector-7" data-name="Vector" d="M1.5,13A1.511,1.511,0,0,1,0,11.5V1.5A1.511,1.511,0,0,1,1.5,0,1.511,1.511,0,0,1,3,1.5v10A1.511,1.511,0,0,1,1.5,13Z" fill="rgba(6,21,43,0.8)"/>
          </g>
        </g>
      </g>
    </svg>

  );
};

export const CloseIcon = ({ className }) => {
  return (
    <svg width={24} height={24} className={className} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 7L7 21" stroke="currentColor" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 7L21 21" stroke="currentColor" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}; 

export const AddIcon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
      <g id="vuesax_bulk_gallery-add" data-name="vuesax/bulk/gallery-add" transform="translate(-748 -252)">
        <g id="gallery-add" transform="translate(748 252)">
          <path id="Vector" d="M58.32,5.707v6.87c0,10.92-6.51,17.43-17.43,17.43H15.75C8.1,30.007,2.58,26.8,0,21.1l.33-.24,14.76-9.9a6.663,6.663,0,0,1,
          7.92.42l1.02.84a6.688,6.688,0,0,0,8.46,0L44.97,1.507a6.688,6.688,0,0,1,8.46,0Z" transform="translate(7.68 35.992)" fill="rgba(6,21,43,0.6)"/>
          <path id="Vector-2" data-name="Vector" d="M56.91,18H48.09C44.28,18,42,15.72,42,11.91V3.09A7.007,7.007,0,0,1,42.66,0H17.43C6.51,0,0,
          6.51,0,17.43V42.57a20.169,20.169,0,0,0,1.68,8.52l.33-.24,14.76-9.9a6.663,6.663,0,0,1,7.92.42l1.02.84a6.688,6.688,0,0,0,8.46,0L46.65,31.5a6.688,6.688,
          0,0,1,8.46,0L60,35.7V17.34A7.007,7.007,0,0,1,56.91,18Z" transform="translate(6 6)" fill="rgba(6,21,43,0.6)" opacity="0.4"/>
          <path id="Vector-3" data-name="Vector" d="M14.28,7.14A7.14,7.14,0,1,1,7.14,0,7.14,7.14,0,0,1,14.28,7.14Z" transform="translate(19.86 16.86)" fill="rgba(6,21,43,0.6)"/>
          <path id="Vector-4" data-name="Vector" d="M14.91,0H6.09C2.28,0,0,2.28,0,6.09v8.82C0,18.72,2.28,21,6.09,21h8.82C18.72,21,21,18.72,21,14.91V6.09C21,
          2.28,18.72,0,14.91,0Zm2.82,11.79a1.94,1.94,0,0,1-1.23.54H12.27l.03,4.17a1.9,1.9,0,0,1-.57,1.29,1.771,1.771,0,0,1-1.23.51,1.805,1.805,0,0,
          1-1.8-1.8V12.3l-4.2.03A1.831,1.831,0,0,1,2.7,10.5,1.805,1.805,0,0,1,4.5,8.7l4.2.03V4.53a1.8,1.8,0,1,1,3.6,0L12.27,8.7H16.5a1.805,1.805,0,0,1,1.8,
          1.8A2.042,2.042,0,0,1,17.73,11.79Z" transform="translate(48 3)" fill="rgba(6,21,43,0.6)"/>
          <path id="Vector-5" data-name="Vector" d="M0,0H72V72H0Z" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>

  );
};

export const DocumentAddIcon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
      <g id="vuesax_bulk_add" data-name="vuesax/bulk/add" transform="translate(-492 -252)">
        <g id="add" transform="translate(492 252)">
          <path id="Vector" d="M39.745,0H16.273C6.078,0,0,6.076,0,16.268V39.7C0,49.924,6.078,56,16.273,56H39.717c10.2,0,16.273-6.076,16.273-16.268V16.268C56.018,
          6.076,49.94,0,39.745,0Z" transform="translate(2 2)" fill="#93d50a" opacity="0.4"/>
          <path id="Vector-2" data-name="Vector" d="M46.75,22H27.5V2.75a2.75,2.75,0,0,0-5.5,0V22H2.75a2.75,2.75,0,0,0,0,5.5H22V46.75a2.75,2.75,0,0,0,5.5,
          0V27.5H46.75a2.75,2.75,0,0,0,0-5.5Z" transform="translate(5.25 5.25)" fill="#93d50a"/>
          <path id="Vector-3" data-name="Vector" d="M0,0H60V60H0Z" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>

  );
};


export const ArrowDown = ({ className }) => { 
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="vuesax_linear_arrow-down" data-name="vuesax/linear/arrow-down" transform="translate(-236 -252)">
        <g id="arrow-down" transform="translate(236 252)"> 
          <path id="Vector" d="M11.88,0,6.99,4.89a1.489,1.489,0,0,1-2.1,0L0,0" transform="translate(3.06 5.964)" fill="none" stroke="#06152b" 
            strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
          <path id="Vector-2" data-name="Vector" d="M0,0H18V18H0Z" transform="translate(18 18) rotate(180)" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>
  );
};


export const ArrowUp = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="vuesax_linear_arrow-up" data-name="vuesax/linear/arrow-up" transform="translate(-172 -252)">
        <g id="arrow-up" transform="translate(172 252)">
          <path id="Vector" d="M11.88,5.323,6.99.433a1.489,1.489,0,0,0-2.1,0L0,5.323" transform="translate(3.06 5.964)" fill="none" stroke="currentColor" 
            strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
          <path id="Vector-2" data-name="Vector" d="M0,0H18V18H0Z" transform="translate(18 18) rotate(180)" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>
  );
};


export const Calender_Linear = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="vuesax_linear_calendar" data-name="vuesax/linear/calendar" transform="translate(-492 -188)">
        <g id="calendar" transform="translate(492 188)">
          <path id="Vector" d="M0,0V2.25" transform="translate(6 1.5)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <path id="Vector-2" data-name="Vector" d="M0,0V2.25" transform="translate(12 1.5)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <path id="Vector-3" data-name="Vector" d="M0,0H12.75" transform="translate(2.625 6.817)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <path id="Vector-4" data-name="Vector" d="M13.5,3.75v6.375c0,2.25-1.125,3.75-3.75,3.75h-6c-2.625,0-3.75-1.5-3.75-3.75V3.75C0,1.5,1.125,0,3.75,0h6C12.375,0,13.5,1.5,13.5,3.75Z" transform="translate(2.25 2.625)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <path id="Vector-5" data-name="Vector" d="M0,0H18V18H0Z" fill="none" opacity="0" />
          <path id="Vector-6" data-name="Vector" d="M.495.5H.5" transform="translate(11.277 9.775)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <path id="Vector-7" data-name="Vector" d="M.495.5H.5" transform="translate(11.277 12.025)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <path id="Vector-8" data-name="Vector" d="M.495.5H.5" transform="translate(8.502 9.775)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <path id="Vector-9" data-name="Vector" d="M.495.5H.5" transform="translate(8.502 12.025)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <path id="Vector-10" data-name="Vector" d="M.495.5H.5" transform="translate(5.726 9.775)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
          <path id="Vector-11" data-name="Vector" d="M.495.5H.5" transform="translate(5.726 12.025)" fill="none" stroke="rgba(6,21,43,0.8)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
      </g>
    </svg>

  );
};

export const LikeIcon = ({className, fill = "none", stroke = "#06152b", width=24, height=24 }) =>{
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 24 24`}>
      <g id="heart" transform="translate(-236 -188)">
        <path id="Vector" d="M10.62,17.71a2.181,2.181,0,0,1-1.24,0C6.48,16.72,0,12.59,0,5.59A5.574,5.574,0,0,1,5.56,0,5.515,5.515,0,0,1,10,2.24,5.547,5.547,0,0,1,20,5.59C20,12.59,13.52,16.72,10.62,17.71Z" transform="translate(238 191.1)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(236 188)" fill={fill} opacity="0"/>
      </g>
    </svg>

  );
};

export const ConnectIcon = ({className, fill = "none", stroke= "#06152b"}) =>{
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_linear_link-2" data-name="vuesax/linear/link-2" transform="translate(-108 -252)">
        <g id="link-2">
          <path id="Vector" d="M9.814,0a5.749,5.749,0,1,1-8.13,0" transform="translate(111.246 262.94)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
          <path id="Vector-2" data-name="Vector" d="M1.755,10.249a6,6,0,1,1,8.49,0" transform="translate(116.835 255.161)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
          <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(132 276) rotate(180)" fill={fill} opacity="0"/>
        </g>
      </g>
    </svg>

  );
};

export const LinearCalender = ({className}) =>{
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="vuesax_linear_calendar" data-name="vuesax/linear/calendar" transform="translate(-492 -188)">
        <g id="calendar" transform="translate(492 188)">
          <path id="Vector" d="M0,0V2.25" transform="translate(6 1.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          <path id="Vector-2" data-name="Vector" d="M0,0V2.25" transform="translate(12 1.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          <path id="Vector-3" data-name="Vector" d="M0,0H12.75" transform="translate(2.625 6.817)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          <path id="Vector-4" data-name="Vector" d="M13.5,3.75v6.375c0,2.25-1.125,3.75-3.75,3.75h-6c-2.625,0-3.75-1.5-3.75-3.75V3.75C0,1.5,1.125,0,3.75,0h6C12.375,0,13.5,1.5,13.5,3.75Z" transform="translate(2.25 2.625)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          <path id="Vector-5" data-name="Vector" d="M0,0H18V18H0Z" fill="none" opacity="0"/>
          <path id="Vector-6" data-name="Vector" d="M.495.5H.5" transform="translate(11.277 9.775)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path id="Vector-7" data-name="Vector" d="M.495.5H.5" transform="translate(11.277 12.025)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path id="Vector-8" data-name="Vector" d="M.495.5H.5" transform="translate(8.502 9.775)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path id="Vector-9" data-name="Vector" d="M.495.5H.5" transform="translate(8.502 12.025)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path id="Vector-10" data-name="Vector" d="M.495.5H.5" transform="translate(5.726 9.775)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path id="Vector-11" data-name="Vector" d="M.495.5H.5" transform="translate(5.726 12.025)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </g>
      </g>
    </svg>

  );
};

export const SoldOut = ({className}) =>{
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
      <g id="vuesax_bold_information" data-name="vuesax/bold/information" transform="translate(-108 -508)">
        <g id="information" transform="translate(108 508)">
          <path id="Union_6" data-name="Union 6" d="M40.594,90.708,33.25,84.433a10.61,10.61,0,0,0-5.844-2.125H19.337a9.038,9.038,0,0,1-8.975-8.981v-7.95a10.339,10.339,0,0,0-2.075-5.812l-6.262-7.4a9.336,9.336,0,0,1,0-11.613l6.262-7.394a10.423,10.423,0,0,0,2.075-5.812V19.439a9.022,9.022,0,0,1,8.975-8.962h8.019a10.528,10.528,0,0,0,5.85-2.144l7.431-6.256a9.191,9.191,0,0,1,11.638,0l7.344,6.256A10.644,10.644,0,0,0,65.5,10.477h7.9a8.993,8.993,0,0,1,8.938,8.962v7.906a10.52,10.52,0,0,0,2.15,5.856l6.262,7.35a9.161,9.161,0,0,1-.087,11.663L84.4,59.564a10.617,10.617,0,0,0-2.144,5.856v7.906a9.015,9.015,0,0,1-8.944,8.981H65.419a10.525,10.525,0,0,0-5.85,2.125l-7.338,6.275a9.261,9.261,0,0,1-11.638,0Z" transform="translate(1.598 1.611)" fill="#ff8710"/>
          <path id="Vector" d="M0,0H96V96H0Z" transform="translate(96 96) rotate(180)" fill="none" opacity="0"/>
          <path id="Path_19851" data-name="Path 19851" d="M-29.6-3.528c4.032,0,5.981-2.061,5.981-4.794,0-5.578-8.579-3.987-8.579-6.362,0-.9.762-1.5,2.128-1.5a6.418,6.418,0,0,1,4.346,1.635l1.77-2.33a8.311,8.311,0,0,0-5.824-2.083c-3.517,0-5.667,2.061-5.667,4.547,0,5.622,8.6,3.808,8.6,6.451,0,.851-.851,1.658-2.6,1.658a6.551,6.551,0,0,1-4.749-2.016L-35.917-5.9A8.516,8.516,0,0,0-29.6-3.528Zm15.221,0a7.507,7.507,0,0,0,7.818-7.728,7.507,7.507,0,0,0-7.818-7.728,7.5,7.5,0,0,0-7.8,7.728A7.5,7.5,0,0,0-14.379-3.528Zm0-2.822c-2.755,0-4.525-2.128-4.525-4.906,0-2.8,1.77-4.906,4.525-4.906s4.547,2.106,4.547,4.906C-9.832-8.478-11.624-6.35-14.379-6.35ZM5.187-3.8V-6.6H-1.13V-18.738H-4.311V-3.8Zm8.12,0c4.682,0,7.93-2.957,7.93-7.459s-3.248-7.482-7.93-7.482H7.416V-3.8Zm0-2.8H10.6v-9.341h2.71a4.372,4.372,0,0,1,4.682,4.682A4.472,4.472,0,0,1,13.307-6.6ZM-21.934,17.272a7.507,7.507,0,0,0,7.818-7.728,7.507,7.507,0,0,0-7.818-7.728,7.5,7.5,0,0,0-7.8,7.728A7.5,7.5,0,0,0-21.934,17.272Zm0-2.822c-2.755,0-4.525-2.128-4.525-4.906,0-2.8,1.77-4.906,4.525-4.906s4.547,2.106,4.547,4.906C-17.387,12.322-19.179,14.45-21.934,14.45Zm16.811,2.822c4.547,0,6.742-2.531,6.742-6.25V2.062H-1.628v8.87A3.22,3.22,0,0,1-5.123,14.45a3.237,3.237,0,0,1-3.517-3.517V2.062h-3.226v8.982C-11.865,14.718-9.67,17.272-5.123,17.272ZM11.218,17V4.862h4.346v-2.8H3.647v2.8H8.015V17Z" transform="translate(55.183 48.597)" fill="#fff"/>
        </g>
      </g>
    </svg>

  );
};