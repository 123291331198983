import Header from '../../Components/Header';

export  default  function MainArea ({children}){
  return (
    <div className=''>
      <Header />

      <main>
        <section className="relative  ">
          {children}
          
        </section>
      </main>
      
      
    </div>
  );
}
