import React from "react";

const LoadingScreen = ({ isOverlay=false }) => {
  // Nav on the user side is fixed, so the next element starts at zero
  // while the dashboard side starts at the height of the navbar.
  // For this reason, we need to know if the user is on the dashbord (/account) side
  // to determine the height of the loading screen.
  const isAccountPage = window.location.pathname.includes("/account");
  const loadingScreen = document.getElementsByClassName("loading-screen");
  const nav = document.getElementsByTagName("nav")[0];
  const [style, setStyle] = React.useState({});


  React.useEffect(() => {
    if (loadingScreen && isAccountPage) {
      const distance = loadingScreen[0].getBoundingClientRect().top;
      setStyle({
        paddingTop: distance+"px",
        height: `calc(100vh - ${distance}px)`
      });
    } else if (nav) {
      setStyle({
        paddingTop: nav.offsetHeight+"px"
      });
    }
  }, [nav, loadingScreen]);
  
  return (
    <div className={`loading-screen ${isOverlay ? '_overlay' : ''}`} style={style}>
      <div className="loading-screen__content">
        <img src="/main/icons/loader.svg" alt="loader" />
      </div>
    </div>
  );
};
export default LoadingScreen;