/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../Button';
import H4 from '../Typography/H4';
import H5 from '../Typography/H5';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { MdClose } from 'react-icons/md';
import { getUserState } from '../../utils/utils';
import { createStructuredSelector } from 'reselect';
import Logo from '../../assets/images/cn-logo-dark.png';
import { getSubDomainName } from '../../Services/helpers';
import { NavClose, NavOpen } from '../../assets/images/icons/NavIcons';
import { fetchListingCategories } from '../../redux/listings/listings.actions';
import { selectListingCategories } from '../../redux/listings/listings.selectors';
import ConnectNigeria from '../../Services/ConnectNigeria';
import { alertActions } from '../../redux/alert/alert.actions';
import H3 from '../Typography/H3';
import Input from '../Input';

const Header = ({ fetchListingCategories, listing_categories }) => {
  const selector = useSelector(state => state.authReducer);
  const userState = getUserState(selector);
  const [navOpen, setNavOpen] = useState(false);
  const imgRef = useRef(null);
  const myRefs = useRef([]);
  const [showNewsLetterModal, setShowNewsLetterModal] = useState(false);

  const handleOpen = () => {
    setNavOpen(!navOpen);
  };

  const links = [
    {
      title: 'Articles',
      href: 'https://articles.connectnigeria.com'
    },
    {
      title: 'CNTV',
      href: 'https://cntv.connectnigeria.com'
    },
    {
      title: 'Advertise',
      href: `${process.env.REACT_APP_CN_BASE_URL}/advertise`
    },
    {
      title: 'Business Mixer',
      href: `${process.env.REACT_APP_CN_BASE_URL}/bizmix`
    },
    {
      title: 'ClubConnect',
      href: `https://club.connectnigeria.com/`
    },
    {
      title: 'Newsletter',
      isToggleable: true,
      toggler: () => {
        // First, close the nav for mobile
        setNavOpen(false);
        // Then, show the modal
        setShowNewsLetterModal(!showNewsLetterModal);
      }
    }
  ];

  useEffect(() => {
    fetchListingCategories();
  }, [fetchListingCategories]);

  return (
    <header className="top-0 right-0 left-0 z-50 absolute">
      <div className='z-60 absolute'> Tiny banners!</div>
      <NewsLetterModal open={showNewsLetterModal} handleClose={setShowNewsLetterModal} />
      <section className="bg-white/90 w-full top-0 fixed backdrop-blur-3xl">
        <nav className=" mx-auto px-4 lg:px-8 ">
          <div className="flex py-4 items-center justify-between lg:py-3">
            <div className=" flex items-center">
              <a className="flex-shrink-0" href={process.env.REACT_APP_CN_BASE_URL}>
                <img className="h-9 lg:h-11" src={Logo} alt="connectNigeria Logo" />
              </a>
              <div className="hidden lg:block">
                <div className="flex space-x-4 ml-20 items-baseline">
                  <div className="font-medium text-sm text-mainBlack toggleable lg:text-base">
                    <input
                      type="checkbox"
                      value="selected"
                      id="toggle-one"
                      className="toggle-input"
                    />
                    <label
                      htmlFor="toggle-one"
                      className="cursor-pointer py-2 px-3 block "
                    >
                      Browse ConnectNigeria
                    </label>
                    <div
                      role="toggle"
                      className="bg-white mt-3.5 mb-16 mega-menu   sm:mb-0 "
                    >
                      <div className="flex  flex-wrap h-full w-full justify-between ">
                        <div className="bg-mainGrey-whitesmoke flex flex-col  w-full py-10	 px-10 transition ease-out duration-300 group justify-between sm:w-1/2  lg:w-1/5 hover:bg-mainLightGreen-lighter    ">
                          <H4 weightClass="font-bold" className="leading-tight	">
                            Browse <br /> Connect Nigeria&#39;s Business categories
                          </H4>
                          {/* <span>
                            <a href="#" className="text-base transition ease-out  duration-150 block group-hover:text-mainLightGreen-100">View all categories</a>

                          </span> */}
                        </div>
                        <div className="bg-white shadow-sm w-full lg:w-4/5   ">
                          <div className="grid menu-mega md:grid-cols-2 lg:grid-cols-4">
                            <div
                              onMouseOver={() => {
                                imgRef.current.src =
                                  require('../../assets/images/icons/arrow-right-long.svg').default;
                              }}
                              onMouseOut={() => {
                                imgRef.current.src =
                                  require('../../assets/images/icons/vuesax-linear-arrow-right.svg').default;
                              }}
                              className="border border-r border-b border-t-0 border-l-0 px-10  pt-10 pb-10 transition ease-out duration-300 group hover:bg-mainLightGreen-lighter"
                            >
                              <a
                                href={getSubDomainName('business')}
                                className="flex flex-col h-full"
                              >
                                <H5 weightClass="font-bold" className="mb-4">
                                  Businesses{' '}
                                </H5>
                                <p className="mb-4 pr-5 text-17px text-mainBlack-80">
                                  Nigeria Business Directory Increase your business
                                  visibility and get discovered
                                </p>

                                <div className="flex mt-auto text-base transition ease-out duration-150 group-hover:text-mainLightGreen-100">
                                  Explore
                                  <img
                                    className="ml-4 "
                                    alt=""
                                    ref={imgRef}
                                    src={
                                      require('../../assets/images/icons/vuesax-linear-arrow-right.svg')
                                        .default
                                    }
                                  />
                                </div>
                              </a>
                            </div>
                            {listing_categories?.map((category, index) => (
                              <div
                                onMouseOver={() => {
                                  myRefs.current[index].src =
                                    require('../../assets/images/icons/arrow-right-long.svg').default;
                                }}
                                onMouseOut={() => {
                                  myRefs.current[index].src =
                                    require('../../assets/images/icons/vuesax-linear-arrow-right.svg').default;
                                }}
                                key={category?.id}
                                className="border border-r border-b border-t-0 border-l-0 px-10  pt-10 pb-10 transition ease-out duration-300 group hover:bg-mainLightGreen-lighter"
                              >
                                <a
                                  href={getSubDomainName(category.name)}
                                  className="flex flex-col"
                                >
                                  <H5 weightClass="font-bold" className="mb-4">
                                    {category?.name}{' '}
                                  </H5>
                                  <p className="mb-4 pr-5 text-17px text-mainBlack-80">
                                    {category?.description}
                                  </p>

                                  <div className="flex mt-auto text-base transition  ease-out duration-150 group-hover:text-mainLightGreen-100">
                                    Explore
                                    <img
                                      className="ml-4"
                                      ref={el => (myRefs.current[index] = el)}
                                      id={`${category?.name}`}
                                      alt="arrow"
                                      src={
                                        require('../../assets/images/icons/vuesax-linear-arrow-right.svg')
                                          .default
                                      }
                                    />
                                  </div>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {links.map((link, i) => (
                    <a
                      className="font-medium text-mainBlack text-sm py-2 px-3 lg:text-base"
                      href={link.href || '#'}
                      key={i}
                      onClick={link.isToggleable ? link.toggler : null}
                    >
                      {link.title}
                    </a>
                  ))}
                </div>
              </div>
            </div>

            <div className="hidden lg:block">
              <div className="flex ml-4 items-center md:ml-6">
                <Button
                  heightClass="h-11"
                  buttonRadius="largeRadius"
                  // className="text-base"
                  variant="green"
                  className="text-sm py-6 px-14 whitespace-nowrap lg:text-base mr-4"
                  href="/create-listing"
                >
                  List Now
                </Button>

                <div className="text-left relative inline-block">
                  <div>
                    {userState.isLoggedIn ? (
                      <Button
                        heightClass="h-11"
                        buttonRadius="largeRadius"
                        className="text-base"
                        href={`/account/start`}
                      >
                        My Account
                      </Button>
                    ) : (
                      <Button
                        heightClass="h-11"
                        buttonRadius="largeRadius"
                        className="text-base"
                        href={`/login`}
                      >
                        Login
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex ml-auto lg:hidden">
              <button
                onClick={handleOpen}
                className="rounded-md  p-2 text-gray-800 inline-flex items-center justify-center hover:text-gray-300 focus:outline-none"
              >
                {!navOpen ? <NavOpen /> : <NavClose />}
              </button>
            </div>
          </div>
        </nav>

        <nav
          className={`${!navOpen ? 'hidden scale-out-hor-right' : 'block scale-in-hor-right'
            } vista-white h-screen lg:hidden overflow-y-auto pb-24`}
        >
          <div className="container flex flex-col mx-auto px-2 gap-2 sm:px-3">
            <div className="font-medium text-base text-mainBlack p-2" href="/#">
              Browse ConnectNigeria
              <div className="grid gap-4 grid-cols-2">
                <a
                  href={getSubDomainName('business')}
                  className="rounded font-bold text-lg p-1 transition ease-out duration-150 block hover:text-mainLightGreen-100"
                >
                  Businesses
                </a>
                {listing_categories?.map(category => (
                  <a
                    key={category?.id}
                    href={getSubDomainName(category.name)}
                    className="rounded font-bold text-lg p-1 transition ease-out duration-150 block hover:text-mainLightGreen-100"
                  >
                    {category?.name}
                  </a>
                ))}
              </div>
            </div>

            {links.map((link, i) => (
              <a
                className="rounded-xl text-base font-medium text-mainBlack py-1 px-2 hover:bg-white"
                href={link.href || '#'}
                key={i}
                onClick={link.isToggleable ? link.toggler : null}
              >
                {link.title}
              </a>
            ))}

            <Button
              heightClass="h-11"
              buttonRadius="rounded-xl"
              target="_blank"
              variant="green"
              className="font-medium mt-auto text-mainBlack text-center w-full justify-center py-2 px-3"
              href={`/create-listing`}
            >
              List Now
            </Button>

            <div className="py-2">
              {userState.isLoggedIn ? (
                <Button
                  heightClass="h-11"
                  buttonRadius="rounded-xl"
                  className="w-full justify-center"
                  target="_blank"
                  href={`/account/start`}
                >
                  My Account
                </Button>
              ) : (
                <Button
                  heightClass="h-11"
                  buttonRadius="rounded-xl"
                  className="w-full justify-center"
                  target="_blank"
                  href={`/login`}
                >
                  Login
                </Button>
              )}
            </div>
          </div>
        </nav>
      </section>
    </header>
  );
};

const NewsLetterModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState();
  const dispatch = useDispatch();

  const handleNewsLetterSubmission = () => {
    ConnectNigeria.post(`/generic/newsletter-subscribers`, { email })
      .then(res => {
        setEmail('');
        dispatch(alertActions.success(res?.data?.message));

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch(err => {
        dispatch(alertActions.error(err?.response?.data?.errors.email[0]));
      });
  };

  return (
    <Modal
      className="adModal bg-none"
      centered
      visible={open}
      onOk={() => handleClose(false)}
      onCancel={() => handleClose(false)}
      footer={null}
      closeIcon={<MdClose className="h-full text-white text-2xl" />}
    >
      <section className="">
        <div className="bg-mainBlack-100 p-10">
          <div className={`items-center flex-col lg:flex-row`}>
            <div className="mb-6">
              <div className="lg:w-[410px] mb-6 lg:mb-0 text-center">
                <H3
                  color="text-white"
                  sizeClass="text-3xl lg:text-5xl"
                  weightClass="font-bold"
                  className=" mb-2"
                >
                  Sign up for news and special offers
                </H3>
                <p className="text-white text-base font-medium ">
                  Sign up to our newsletter now and get the latest news and
                  special offers delivered directly to your inbox.
                </p>
              </div>
            </div>
            <div className="w-full">
              <form action="" className="flex flex-col lg:flex-row w-full">
                <Input
                  onInputChange={e => setEmail(e.target.value)}
                  heightClass="h-[46px] lg:h-[56px]"
                  className={'lg:mr-3'}
                  placeholder={'Enter your email...'}
                />
                <Button
                  type={'button'}
                  onClick={handleNewsLetterSubmission}
                  heightClass={'h-[46px] lg:h-[56px]'}
                  buttonRadius={'smallRadius'}
                  variant="green"
                  className={'lg:ml-3 font-medium justify-center cursor-pointer'}
                >
                  Subscribe
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchListingCategories: () => dispatch(fetchListingCategories())
  };
};

const mapStateToProps = createStructuredSelector({
  listing_categories: selectListingCategories
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
