export function NavOpen() {
  return (
    <svg
      width="20"
      height="18"
      className="scale-in-center"
      viewBox="0 0 20 18"
      fill="#232639'"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 16.5062C20 17.1937 19.554 17.75 19.005 
      17.75H10.995C10.7311 17.75 10.478 17.6189 10.2914 
      17.3857C10.1048 17.1524 10 16.8361 10 16.5062C10 16.1763 10.1048 15.86 10.2914 15.6267C10.478
       15.3935 10.7311 15.2625 10.995 15.2625H19.005C19.555 15.2625 20 15.8187 20 16.5062Z"
        fill="#232639'"
      />
      <path
        d="M20 8.99998C20 9.68748 19.554 10.2437 19.005 10.2437H0.995C0.731109 10.2437 0.478028
       10.1127 0.291429 9.87944C0.10483 9.64619 0 9.32984 0 8.99998C0 8.67011 0.10483 8.35376 0.291429
        8.12051C0.478028 7.88726 0.731109 7.75623 0.995 7.75623H19.005C19.555 7.75623 20 8.31373 20
         8.99998Z"
        fill="#232639'"
      />
      <path
        d="M19.005 2.7375C19.2689 2.7375 19.522 2.60646 19.7086 2.37321C19.8952 2.13997 20
       1.82361 20 1.49375C20 1.16389 19.8952 0.847534 19.7086 0.614286C19.522 0.381037 19.2689
        0.25 19.005 0.25H6.995C6.86433 0.25 6.73495 0.282171 6.61423 0.344675C6.49351 0.407179
         6.38382 0.498793 6.29143 0.614286C6.19903 0.729778 6.12574 0.866889 6.07574 1.01779C6.02574
          1.16869 6 1.33042 6 1.49375C6 1.65708 6.02574 1.81881 6.07574 1.96971C6.12574 2.12061 6.19903
           2.25772 6.29143 2.37321C6.38382 2.48871 6.49351 2.58032 6.61423 2.64282C6.73495 2.70533 6.86433 2.7375 6.995 2.7375H19.005Z"
        fill="#232639'"
      />
    </svg>
  );
}

export function NavClose() {
  return (
    <svg
      width="30"
      height="30"
      className="scale-in-center"
      viewBox="0 0 30 30"
      fill="#232639'"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7629 15L22.1379 9.63749C22.3733 9.40211
       22.5055 9.08286 22.5055 8.74999C22.5055 8.41711 22.3733
        8.09787 22.1379 7.86249C21.9025 7.62711 21.5833 7.49487
        21.2504 7.49487C20.9175 7.49487 20.5983 7.62711 20.3629
        7.86249L15.0004 13.2375L9.63791 7.86249C9.40253 7.62711
        9.08329 7.49487 8.75041 7.49487C8.41753 7.49487 8.09829
        7.62711 7.86291 7.86249C7.62753 8.09787 7.4953 8.41711
        7.4953 8.74999C7.4953 9.08286 7.62753 9.40211 7.86291
        9.63749L13.2379 15L7.86291 20.3625C7.74575 20.4787 7.65276 20.6169 7.5893 20.7693C7.52584
        20.9216 7.49316 21.085 7.49316 21.25C7.49316 21.415 7.52584 21.5784 7.5893
        21.7307C7.65276 21.883 7.74575 22.0213 7.86291 22.1375C7.97912 22.2546 8.11737
        22.3476 8.26969 22.4111C8.42202 22.4746 8.5854 22.5072 8.75041 22.5072C8.91543
        22.5072 9.07881 22.4746 9.23113 22.4111C9.38346 22.3476 9.52171 22.2546 9.63791
        22.1375L15.0004 16.7625L20.3629 22.1375C20.4791 22.2546 20.6174 22.3476 20.7697
        22.4111C20.922 22.4746 21.0854 22.5072 21.2504 22.5072C21.4154
        22.5072 21.5788 22.4746 21.7311 22.4111C21.8835 22.3476 22.0217 22.2546 22.1379
        22.1375C22.2551 22.0213 22.3481 21.883 22.4115 21.7307C22.475 21.5784 22.5077 21.415 
        22.5077 21.25C22.5077 21.085 22.475 20.9216 22.4115 20.7693C22.3481 20.6169 22.2551 
        20.4787 22.1379 20.3625L16.7629 15Z"
        fill="#232639'"
      />
    </svg>
  );
}
