export const routeNames = {
  GET_STARTED: 'start',
  DASHBOARD: 'dashboard',
  BUSINESSES: 'businesses',
  LISTINGS: 'listings',
  ADVERTS: 'adverts',
  INVOICES: 'invoices',
  CONNECTIONS: 'connections',
  CLUBCONNECT: 'clubconnect',
  QUOTE_REQUEST: 'quote-request',
  SAVED: 'saved'
};

export const modelTypes = {
  BUSINESS: 'business',
  LISTING: 'listing'
};

export const dataTypes = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  JSON: 'json',
  ARRAY: 'array',
  OBJECT: 'object',
  JSON_ARRAY: 'json_array',
  JSON_OBJECT: 'json_object',
  DATE: 'date',
  FILE: 'file'
};
