import React from 'react';
import SideNav from '../../Views/Account/reusables/SideNav';
import { useLocation } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';

export default function AccountArea({ children }) {
  const location = useLocation();

  // Stick the inner nav to the top of the page
  const onScroll = e => {
    const innerNav = document.querySelector('.inner__nav');
    if (innerNav) {
      if (e.target.offsetTop > 0) {
        innerNav.style.position = 'sticky';
        innerNav.style.top = '0';
        innerNav.style.zIndex = '1';
      } else {
        innerNav.style.position = 'relative';
        innerNav.style.top = '0';
        innerNav.style.zIndex = '0';
      }
    }
  };

  const [mobileNavIsOpen, setMobileNavIsOpen] = React.useState(false);
  React.useEffect(() => {
    setMobileNavIsOpen(false);
  }, [location]);

  return (
    <div
      className="bg-accountLightGreen user-account"
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div className={`user-account-sidenav ${mobileNavIsOpen ? 'open' : ''}`}>
        <SideNav />
      </div>

      <div className="user-account-content">
        <div className="user-account-mobile-nav">
          <div className="_nav-mobile-toggle">
            <img
              src="/account/icons/nav/hamburger.svg"
              alt="hamburger"
              onClick={() => setMobileNavIsOpen(!mobileNavIsOpen)}
            />
          </div>

          {mobileNavIsOpen && (
            <div
              className="user-account-sidenav-close-btn z-50"
              onClick={() => setMobileNavIsOpen(false)}
            >
              <img src="/account/icons/close.svg" alt="close" />
            </div>
          )}
        </div>

        <div onScroll={onScroll} className="user-account__body">
          <SimpleReactLightbox>{children}</SimpleReactLightbox>
        </div>
      </div>
    </div>
  );
}
