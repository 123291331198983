import { lazy } from 'react';
import NewBizmixLayout from '../Layouts/Users/NewBizmixLayout';

export const NewBizmixRoutes = [
  {
    exact: true,
    path: '/bizmix',
    layout: NewBizmixLayout,
    component: lazy(() => import('../Views/BizmixNew/HomePage'))
  },
  {
    exact: true,
    path: '/bizmixold',
    layout: NewBizmixLayout,
    component: lazy(() => import('../Views/Bizmix/BizmixIndexPage'))
  },
  {
    exact: true,
    path: '/bizmixer',
    layout: NewBizmixLayout,
    component: lazy(() => import('../Views/Bizmix/BizmixIndexPage'))
  },
  {
    exact: true,
    layout: NewBizmixLayout,
    path: '/bizmix/gallery',
    component: lazy(() => import('../Views/Bizmix/BizmixGalleryPage'))
  },
  {
    exact: true,
    layout: NewBizmixLayout,
    path: '/bizmix/signup',
    component: lazy(() => import('../Views/Bizmix/BizmixRegistrationPage'))
  },
  {
    exact: true,
    layout: NewBizmixLayout,
    path: '/bizmix/survey',
    component: lazy(() => import('../Views/Bizmix/BizmixSurveyPage'))
  },
  {
    exact: true,
    layout: NewBizmixLayout,
    path: '/bizmix/payment',
    component: lazy(() => import('../Views/Bizmix/BizmixPaymentPage'))
  },
  {
    exact: true,
    layout: NewBizmixLayout,
    path: '/bizmix/success',
    component: lazy(() => import('../Views/Bizmix/BizmixSuccessPage'))
  }
];
