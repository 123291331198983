import { deleteCookie } from '../utils/utils';
import ConnectNigeria from './ConnectNigeria';

export const authRequests = {
  requestLogin,
  requestSocialLogin,
  requestLogout,
  requestRegister,
  requestGooglelogin
};

async function requestGooglelogin(){
  return ConnectNigeria.get(`${process.env.REACT_APP_API_HOST}/user/auth/google/redirect-url`)
  .then(response => {
    return {
          success: true,
          data: response.data
      };
  })
  .catch(error => {
      return {
          success: false,
          error: error.response.data
      };
  });
}


async function requestLogin(email, password) {
  return await ConnectNigeria.post('/user/auth/login', {
    email,
    password
  })
    .then(res => {
      return {
        success: true,
        data: res.data
      };
    })
    .catch(err => {
      return {
        success: false,
        error: err.response.data
      };
    });
}

function requestLogout() {
  deleteCookie('auth:hash');
}

async function requestRegister(data) {
  return await ConnectNigeria.post('/user/auth/register', {
    ...data,
    callbackUrl: window.location.origin
  })
    .then(res => {
      return {
        success: true,
        data: res.data
      };
    })
    .catch(err => {
      return {
        success: false,
        error: err.response.data
      };
    });
}


async function requestSocialLogin(userId, access_token, refId, provider) {
  return await ConnectNigeria.post('/user/auth/social/'+provider, {
    userId,
    access_token,
    referee_code: refId,
    callbackUrl: process.env.REACT_APP_CN_BASE_URL + '/verify'
  })
    .then(res => {
      return {
        success: true,
        data: res.data
      };
    })
    .catch(err => {
      return {
        success: false,
        error: err.response.data
      };
    });
}