import { Link } from "react-router-dom";
import { ArrowRight } from "../../assets/images/icons/arrow-right";
const AsLink = ({href, className, children,  target, showArrow, arrowColor, onClick}) => {
  return (
    <Link to={href} className={`${className}  cursor-pointer`} onClick={onClick} target={target}>
      
      {children}
      {showArrow ? <ArrowRight color={arrowColor}/>: null}
    </Link>
  );
};

const AsBtn = ({className, children,  type = 'button',showArrow , arrowColor, onClick}) => {
  return (
    <button className={`${className}  cursor-pointer` } onClick={onClick}  type={type}>
      {children}
      {showArrow ? <ArrowRight color={arrowColor}/>: null}
    </button>
  );
};

const AsTag = ({className, children , arrowColor, showArrow, onClick}) => {
  return (
    <span className={className} onClick={onClick}>
      {children}
      {showArrow ? <ArrowRight color={arrowColor}/>: null}
    </span>
  );
};
export default function Button(
  {variant, href, className,buttonRadius, widthClass, justifyClass, children, onClick, heightClass, target, type, showArrow, arrowColor}) {
  let classes = 'bg-white-100 text-mainBlack-100 border border-mainGrey-default'; // default for green
  switch (variant) {
    case 'green':
      classes = 'bg-mainLightGreen-100 text-white border-0 ';
      break;
    case 'green-inverse':
      classes = 'bg-white text-mainLightGreen-100 border-0';
      break;
    case 'green-light':
      classes = 'bg-mainLightGreen-40 text-mainLightGreen-100 border-0';
      break;
    case 'green-gradient':
      classes = 'green-gradient text-white border-0';
      break;
    case 'orange':
      classes = 'bg-mainOrange-default text-white border-0';
      break;

    case 'orange-inverse':
      classes = 'bg-white text-mainOrange-default border border-mainOrange-default';
      break;
    case 'orange-light':
      classes = 'bg-mainOrange-40 text-mainOrange-100 border-0 ';
      break;
    case 'gray':
      classes = 'bg-mainGrey-whitesmoke text-mainBlack-80 border-0 ';
      break;
    case 'transparent':
      classes = 'bg-transparent border';
      break;
    default:
      break;
  }
  const fullClassName = `${heightClass || 'h-10'}  inline-flex items-center py-1 px-4 focus:outline-none 
  ${buttonRadius || 'rounded-md'}
  ${widthClass}
  ${justifyClass}
  ${classes} ${className || ''} `;
  return href ?
    <AsLink
      className={fullClassName}
      href={href}
      target={target}
      showArrow={showArrow}
      arrowColor={arrowColor}
    >
      {children}
    </AsLink>  : type ?
      <AsBtn
        onClick={onClick}
        className={fullClassName}
        type={type}
        showArrow={showArrow}
        arrowColor={arrowColor}
      >
        {children}
      </AsBtn>  : 
    
      <AsTag 
        className={fullClassName}
        showArrow={showArrow}
        arrowColor={arrowColor}
      >
        {children}
      </AsTag>;
}
