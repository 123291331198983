import { ALERT_TYPES } from "./alert.types";

export const alertActions = {
  success,
  warn,
  error,
  clear
};

function success(message) {
  return {
    type: ALERT_TYPES.SUCCESS,
    message
  };
}

function warn(message) {
  return {
    type: ALERT_TYPES.WARN,
    message
  };
}

function error(message) {
  return {
    type: ALERT_TYPES.ERROR,
    message
  };
}

function clear() {
  return {
    type: ALERT_TYPES.CLEAR
  };
}