import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_TYPES } from '../redux/alert/alert.types';
import { alertActions } from '../redux/alert/alert.actions';

const colorTypes = {
  SUCCESS: 'success',
  WARN: 'warning',
  ERROR: 'danger'
};

export default function Notify({ children }) {
  const dispatch = useDispatch();
  const selector = useSelector(state => state.alertReducer);
  const [alert, setAlert] = React.useState({
    message: null,
    icon: null,
    color: ''
  });

  const timeout = 5000; // 5 seconds

  // Message will be cleared after 5 seconds
  React.useEffect(() => {
    switch (selector.type) {
      case ALERT_TYPES.SUCCESS:
        setAlert({
          message: selector.message,
          icon: '/main/icons/alert-success.svg',
          color: colorTypes.SUCCESS
        });
        setTimeout(() => {
          setAlert({
            message: null,
            icon: null,
            color: ''
          });
          dispatch(alertActions.clear());
        }, timeout);
        break;
      case ALERT_TYPES.WARN:
        setAlert({
          message: selector.message,
          icon: '/main/icons/alert-warning.svg',
          color: colorTypes.WARN
        });
        setTimeout(() => {
          setAlert({
            message: null,
            icon: null,
            color: ''
          });
          dispatch(alertActions.clear());
        }
        , timeout);
        break;
      case ALERT_TYPES.ERROR:
        setAlert({
          message: selector.message,
          icon: '/main/icons/alert-error.svg',
          color: colorTypes.ERROR
        });
        setTimeout(() => {
          setAlert({
            message: null,
            icon: null,
            color: ''
          });
          dispatch(alertActions.clear());
        }
        , timeout);
        break;
      case ALERT_TYPES.CLEAR:
        setAlert({
          message: null,
          icon: null,
          color: ''
        });
        break;
      default:
        break;
    }
  }, [selector]);

  return (
    <div>
      <div className={`alert-container ${alert.message ? 'open' : ''}`}>
        {alert.message && (
          <>
            <div className={'_icon '+alert.color}><img src={alert.icon} alt="alert" /></div>
            <div className='_text'>{alert.message}</div>
            <div className='_close' onClick={() => dispatch(alertActions.clear())}>
              <img src="/main/icons/close.svg" alt="close" />
            </div>
          </>
        )}
      </div>
      {children}
    </div>
  );
}
