import { getSearchParam, base64Decode, getCookie } from '../utils/utils';

export const GuestGuard = ({ children }) => {
  const authData = getCookie('auth:hash');
  const from = getSearchParam('redirect');

  if (authData && base64Decode(authData).success) {
    if (from) {
      window.location.href = from;
      return null;
    } else {
      window.location.href = '/';
      return null;
    }
  }
  return <div>{children}</div>;
};

export const AuthGuard = props => {
  const authData = getCookie('auth:hash');

  if (authData && base64Decode(authData).success) {
    return <div>{props.children}</div>;
  }

  if (window.location.pathname !== '/') {
    const redirectUrl = encodeURIComponent(window.location.href);

    // Wait for the current url to be stored in redirectUrl before redirecting
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_CN_BASE_URL}/login?redirect=${redirectUrl}`;
    }, 100);
    return;
  }
};
