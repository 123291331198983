import { BusinessTypes } from "./businesses.types";

const INITIAL_STATE = {
  featured_businesses: [],
  loading: false,
  business: {},
  businesses: [],
  categories: [],
};

const businessReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BusinessTypes.FETCH_FEATURED_BUSINESS_PROCESS:
      return { ...state, loading: true, featured_businesses: [] };
    case BusinessTypes.FETCH_FEATURED_BUSINESS_SUCCESS:
      return { ...state, loading: false, featured_businesses: payload };

    case BusinessTypes.FETCH_SINGLE_BUSINESS_DETAIL_PROCESS:
      return { ...state, loading: true, business: {} };
    case BusinessTypes.FETCH_SINGLE_BUSINESS_DETAIL_SUCCESS:
      return { ...state, loading: false, business: payload };

    case BusinessTypes.FETCH_ALL_BUSINESSES_PROCESS:
      return { ...state, loading: true, businesses: [] };
    case BusinessTypes.FETCH_ALL_BUSINESSES_SUCCESS:
      return { ...state, loading: false, businesses: payload };

    case BusinessTypes.FETCH_BUSINESS_CATEGORIES_PROCESS:
      return { ...state, loading: true, categories: [] };
    case BusinessTypes.FETCH_BUSINESS_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: payload };
    default:
      return state;
  }
};

export default businessReducer;